import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCommentsWithMostSubcomments,
  fetchCommentsWithMostRatings,
  fetchAllComments,
} from "../../redux/thunk/comment";
import { Link, useLocation } from "react-router-dom";
import { RootState, AppDispatch } from "../../redux/store";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { formatDistanceToNow } from "date-fns";
import {
  TOPREVIEW_BUTTON1,
  TOPREVIEW_BUTTON1_SE,
  TOPREVIEW_BUTTON2,
  TOPREVIEW_BUTTON2_SE,
  TOP_COMMENTS_TEXT,
  TOP_COMMENTS_TEXT_SE,
} from "../../constants/textConstants";
import { allTytles, btnTopReview } from "../../constants/styleConstants";
import {
  containerStyle,
  cardContainerStyle,
  cardStyle,
  cardContentStyle,
  cardAvatarContainerStyle,
  cardAvatarStyle,
  cardAvatarImageStyle,
} from "../../constants/styleConstants";
import { AiOutlineLike } from "react-icons/ai";
import { useLanguage } from "../languages/LanguageContext";
import arrowImg from "../../assets/arrow.png"; // Import the arrow image

function TopReviews() {
  const { currentLanguage } = useLanguage();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const commentsWithMostSubcomments = useSelector(
    (state: RootState) => state.comment.commentsWithMostSubcomments
  );
  const commentsWithMostRatings = useSelector(
    (state: RootState) => state.comment.commentsWithMostRatings
  );
  const allComments = useSelector(
    (state: RootState) => state.comment.allComments
  );
  const isLoading = useSelector((state: RootState) => state.comment.loading);
  const location = useLocation();

  // State to control the number of displayed comments
  const [displayedCount, setDisplayedCount] = useState(12);
  const [showTopRated, setShowTopRated] = useState<
    "subcomments" | "ratings" | "all"
  >("all");
  const [showScrollArrow, setShowScrollArrow] = useState(false); // State to handle scroll arrow visibility

  const handleCommentClick = (commentId: string) => {
    navigate(`/comment/${commentId}`);
  };

  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    dispatch(fetchCommentsWithMostSubcomments());
    dispatch(fetchCommentsWithMostRatings());
    dispatch(fetchAllComments());
  }, [dispatch]);

  // Effect to track scroll position
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 500) {
        setShowScrollArrow(true);
      } else {
        setShowScrollArrow(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  if (isLoading) {
    return <Box>Loading...</Box>;
  }

  const renderComments =
    showTopRated === "ratings"
      ? commentsWithMostRatings.slice(0, 12)
      : showTopRated === "subcomments"
      ? commentsWithMostSubcomments.slice(0, 12)
      : allComments.slice(-displayedCount).reverse();

  return (
    <Box sx={containerStyle}>
      <Typography variant="h4" sx={allTytles}>
        {currentLanguage === "se" ? TOP_COMMENTS_TEXT_SE : TOP_COMMENTS_TEXT}
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: 1,
          marginBlock: "20px",
          fontSize: "12px",
        }}
      >
        <Button
          sx={btnTopReview}
          onClick={() => setShowTopRated("subcomments")}
          className={showTopRated === "subcomments" ? "Mui-selected" : ""}
        >
          {currentLanguage === "se" ? TOPREVIEW_BUTTON1_SE : TOPREVIEW_BUTTON1}
        </Button>
        <Button
          sx={btnTopReview}
          onClick={() => setShowTopRated("ratings")}
          className={showTopRated === "ratings" ? "Mui-selected" : ""}
        >
          {currentLanguage === "se" ? TOPREVIEW_BUTTON2_SE : TOPREVIEW_BUTTON2}
        </Button>
        <Button
          sx={btnTopReview}
          onClick={() => setShowTopRated("all")}
          className={showTopRated === "all" ? "Mui-selected" : ""}
        >
          {currentLanguage === "se" ? "Alla kommentarer" : "All Comments"}
        </Button>
      </Box>

      <Box sx={cardContainerStyle}>
        {renderComments.map((comment) => (
          <Link
            to={`/business/${comment.businessId}#comment-${comment._id}`}
            key={`comment-${comment._id}`}
          >
            <Card
              id={`comment-${comment._id}`}
              style={cardStyle}
              onMouseEnter={(e) => {
                e.currentTarget.style.transform = "scale(1.02)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = "scale(1)";
              }}
              onClick={() => comment._id && handleCommentClick(comment._id)}
            >
              <Box style={cardAvatarContainerStyle}>
                <Box style={cardAvatarStyle}>
                  <img
                    src={comment.businessIcon}
                    style={cardAvatarImageStyle}
                    alt={comment.businessId}
                    loading="lazy"
                  />
                </Box>

                {comment.image ? (
                  <CardMedia
                    component="img"
                    height="240"
                    image={comment.image}
                    alt={comment.businessIcon}
                    loading="lazy"
                  />
                ) : (
                  <Box
                    sx={{
                      height: "240px",
                      backgroundColor: "#f0f0f0",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography variant="body2" color="textSecondary">
                      No Image Available
                    </Typography>
                  </Box>
                )}
              </Box>
              <CardContent>
                <Typography sx={cardContentStyle}>
                  {comment.text.length > 20
                    ? `${comment.text.slice(0, 20)}...`
                    : comment.text}
                </Typography>
              </CardContent>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  p: "5px",
                  borderTop: "1px solid grey",
                }}
              >
                <Typography variant="body2">
                  {comment.subComments?.length} comments
                </Typography>
                <Typography variant="body2">
                  {comment.ratings.length} <AiOutlineLike />
                </Typography>
                <Typography variant="caption">
                  {comment.date
                    ? formatDistanceToNow(new Date(comment.date), {
                        addSuffix: true,
                      })
                    : "Date Unavailable"}
                </Typography>
              </Box>
            </Card>
          </Link>
        ))}
      </Box>

      {/* "Load More" button for fetching additional comments */}
      {showTopRated === "all" && displayedCount < allComments.length && (
        <Button
          onClick={() => setDisplayedCount((prev) => prev + 9)}
          sx={{
            display: "block",
            margin: "20px auto",
            padding: "10px 20px",
            backgroundColor: "#f0f0f0",
            "&:hover": {
              backgroundColor: "#e0e0e0",
            },
          }}
        >
          Load More
        </Button>
      )}

      {/* Arrow for scrolling back to the top */}
      {showScrollArrow && (
        <IconButton
          onClick={scrollToTop}
          sx={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            width: "60px",
            height: "80px",
            backgroundColor: "transparent",
            padding: 0,
            "&:hover": {
              transform: "scale(1.1)",
            },
            "&:focus": {
              outline: "none",
              boxShadow: "none",
            },
          }}
        >
          <img
            src={arrowImg}
            alt="Scroll to top"
            loading="lazy"
            style={{
              width: "40px",
              height: "auto",
            }}
          />
        </IconButton>
      )}
    </Box>
  );
}

export default TopReviews;
